import React, { Component } from 'react';

const initData = {
    img: "/img/logo_trust.png",
    logo: "/img/logo_trust.png",
    blockchain: "/img/matic.png",
    tokenlogo: "/img/matic.png",
    title: "Trust",
    name: "Trust",
    symbol: "TRT",
    heading: "Project Summery",
    stakedet: "Staking Details",
    plandet: "Reward Plan",
    maxsupply: "1M",
    price: "1 USDT",
    currentsupply: "1 M TRT",
    // supplymech: "Mint on Demand",
    progress: "25%",
    mecha: "0/100,069 MECHA",
    busd: "0 BUSD",
    actionBtn: "Join Now",
    video_img: "/img/thumb_6.png",
    video_icon: "fa-solid fa-play",
    video_link: "https://www.youtube.com/watch?v=fzBTvDraO5U"
}

const summaryData = {
    title: "Trust-Stake",
    img: "/img/logo_trust.png",
    content_1: "TrustStake is staking platform for Trust(TRT) token formed on time tested polygon(Matic) blockchain and aimed to provide strong transactional experience for users. Trust token is formed to demonstrate liquidity programs in real manners.",
    content_2: "This will guide through how the liquidity works in the value creation of a token. This will provide Amazing staking returns to users in small periods of staking Trust tokens.",
    content_3: "Stake your TRT on TrustStake and enjoy amazing returns on your stake."
}

const overviewData = {
    list_heading: "Stake Amount & Rewards",
    content: "Reward plan is really simple and automated. There will be no interference by anyone who controls. All rewards are contract based.",
}

const tokenmetricsData = {
    title: "Token Metrics",
    content: "Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar."
}

const roadmapData = {
    title: "Roadmap",
    content_1: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean.",
    content_2: "A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country, in which roasted parts of sentences fly into your mouth."
}

const socialData = [
    {
        id: "1",
        link: "twitter",
        icon: "fab fa-twitter"
    },
    {
        id: "2",
        link: "telegram",
        icon: "fab fa-telegram"
    },
    {
        id: "3",
        link: "globe",
        icon: "fas fa-globe"
    },
    {
        id: "4",
        link: "discord",
        icon: "fab fa-discord"
    }
]

const overviewList = [
    {
        id: "1",
        content: "Minimum Stake: 40$",
    },
    {
        id: "2",
        content: "Multiple Stake: Not Allowed",
    },
    {
        id: "3",
        content: "Stake Round: 360 Days",
    },
    {
        id: "4",
        content: "Matrix Reward: 20$",
    },
    {
        id: "5",
        content: "After First matrix completion, you may quit matrix with 60$",
    },
    {
        id: "6",
        content: "First Level Reward: 1$ Each Id",
    },
    {
        id: "7",
        content: "2nd Level Referral Reward: 1$ Each Id",
    },
    {
        id: "8",
        content: "3rd & 4th Level Referral Reward: 1$ Each Id",
    },
    {
        id: "9",
        content: "5th  Referral Reward: 1$ Each Id",
    },
    {
        id: "10",
        content: "6th Level Referral Reward: 5$ Each Id",
    },
    {
        id: "11",
        content: "Topaz Rank 4 id Direct Needed, Reward = 5000$",
    },
    {
        id: "12",
        content: "Master Rank 16 Id Needed on Level 2, Reward = 20000$",
    },
    {
        id: "13",
        content: "Ambassade Rank 3 to 6 Level 64 Id needed, Rewards 25000$",
    }
]

const tokenList = [
    {
        id: "1",
        title: "Fundraised:",
        content: "$500,000",
    },
    {
        id: "2",
        title: "Platfrom Raise:",
        content: "$120,000 + $5,000",
    },
    {
        id: "3",
        title: "Price:",
        content: "$0.05",
    },
    {
        id: "4",
        title: "Lock-up:",
        content: "25% unlocked on TGE, 4 months cliff, then 5% on a monthly basis",
    }
]

class ProjectSingle extends Component {
    state = {
        initData: {},
        summaryData: {},
        overviewData: {},
        tokenmetricsData: {},
        roadmapData: {},
        socialData: [],
        overviewList: [],
        tokenList: []
    }
    componentDidMount(){
        this.setState({
            initData: initData,
            summaryData: summaryData,
            overviewData: overviewData,
            tokenmetricsData: tokenmetricsData,
            roadmapData: roadmapData,
            socialData: socialData,
            overviewList: overviewList,
            tokenList: tokenList
        });
    }
    render() {
        return (
            <section className="item-details-area">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            {/* Intro */}
                            <div className="intro d-flex justify-content-between align-items-end m-0 mb-4">
                                <div className="intro-content">
                                    <h3 className="mt-3 mb-0">{ this.state.initData.heading }</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-between">
                        <div className="col-12 col-lg-12 col-xl-12 ">
                            {/* Project Card */}
                            <div className="card project-card no-hover">
                                <div className="media">
                                    <img className="card-img-top avatar-max-lg" src={ this.state.initData.img } alt="" />
                                    <div className="media-body ml-4">
                                        <h4 className="m-0">{ this.state.initData.title }</h4>
                                        <div className="countdown-times">
                                            <h6 className="my-2">TRT</h6>
                                        </div>
                                    </div>
                                </div>
                                {/* Project Body */}
                                <div className="card-body">
                                    <div className="items">
                                        {/* Single Item */}
                                        <div className="single-item">
                                            <span>Toke Name</span>
                                            <span> { this.state.initData.name }</span>
                                        </div>
                                        {/* Single Item */}
                                        <div className="single-item">
                                            <span>Toke Symbol</span>
                                            <span> { this.state.initData.symbol }</span>
                                        </div>
                                        <div className="single-item">
                                            <span>Toke Logo</span>
                                            <span> <img src={ this.state.initData.logo }  style={{width: '20px'}} alt="plg" /></span>
                                        </div>
                                        {/* Single Item */}
                                        <div className="single-item">
                                            <span>Price</span>
                                            <span> ≈ { this.state.initData.price }</span>
                                        </div>
                                        {/* Single Item */}
                                        <div className="single-item">
                                            <span>Max. Supply</span>
                                            <span> { this.state.initData.maxsupply }</span>
                                        </div>                                        
                                        {/* Single Item */}
                                        <div className="single-item">
                                            <span>Current Supply</span>
                                            <span> { this.state.initData.currentsupply }</span>
                                        </div>
                                        {/* <div className="single-item">
                                            <span>Supply Mechanism</span>
                                            <span> { this.state.initData.supplymech }</span>
                                        </div> */}
                                    </div>
                                </div>
                                {/* Project Footer */}
                                <div className="project-footer d-flex align-items-center mt-4 mt-md-5">
                                    <a className="btn btn-bordered-white btn-smaller" href="/login">{ this.state.initData.actionBtn }</a>
                                    {/* Social Share */}
                                    <div className="social-share ml-auto">
                                        <ul className="d-flex list-unstyled">
                                            {this.state.socialData.map((item, idx) => {
                                                return (
                                                    <li key={`sd_${idx}`}>
                                                        <a href="/#">
                                                            <i className={ item.icon } />
                                                        </a>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                </div>
                                {/* Blockchain Icon */}
                                <div className="blockchain-icon">
                                    <img src={ this.state.initData.blockchain } alt="polygon" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                        <div className="col-12">
                            {/* Intro */}
                            <div className="intro d-flex justify-content-between align-items-end m-0 mb-3">
                                <div className="intro-content">
                                    <h3 className="mt-3 mb-0">{ this.state.initData.stakedet }</h3>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className="col-md-12 col-lg-12 col-xl-12 items  mt-lg-0">                           
                            <div className="card project-card single-item-content no-hover item ml-lg-4 ">
                                <h3 className="d-flex justify-content-center m-0">{this.state.summaryData.title}</h3>
                                    <div className='d-flex justify-content-center mt-4'>    
                                        <img src={ this.state.summaryData.img } style={{width: '300px'}} alt="polygon" />
                                    </div> 
                                <p>{this.state.summaryData.content_1}</p>
                                <p>{this.state.summaryData.content_2}</p>
                                <p>{this.state.summaryData.content_3}</p>
                            </div>
                                <div className="row">
                                    <div className="col-12">
                                        {/* Intro */}
                                        <div className="intro d-flex justify-content-between align-items-end m-0">
                                            <div className="intro-content">
                                                <h3 className="mt-3 mb-0">{ this.state.initData.plandet }</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            <div className="card project-card single-item-content no-hover item ml-lg-4">
                                <p className="mb-0">{this.state.overviewData.content}</p>
                                <h4>{this.state.overviewData.list_heading}</h4>
                                <ul>
                                    {this.state.overviewList.map((item, idx) => {
                                        return (
                                            <li key={`odl_${idx}`}>{item.content}</li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ProjectSingle;