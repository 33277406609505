import React from 'react';
import { ToastContainer } from 'react-toastify';
import { ThirdwebProvider, metamaskWallet, walletConnect, trustWallet, useSwitchAccount} from "@thirdweb-dev/react";
import MyRouts from './routers/routes';
const activeChain = "polygon";

function App() {
  return (
    <ThirdwebProvider
    clientId={process.env.REACT_APP_PROJECT_ID}
    activeChain={activeChain}
    autoConnect={true}
    supportedWallets={[
      metamaskWallet({ recommended: true }),
      walletConnect({ recommended: true }),
      trustWallet({ recommended: true }),
    ]}
      >
    <div>
      <ToastContainer />
      <MyRouts />
    </div>
    </ThirdwebProvider>
  );
}

export default App;