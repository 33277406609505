import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ethers } from "ethers";
import { Web3Button, useAddress, useContract, useContractRead } from "@thirdweb-dev/react";
import { CONTRACT_ADDRESS } from "../../const/addresses";

const Locker = () => {
    const address = useAddress();
    const { contract } = useContract(CONTRACT_ADDRESS);
    const { data, isLoading } = useContractRead(contract, "userInfoLocker", [address]);

    // Parse BigNumber values and unlocked status
    const lockedAmount = data ? parseFloat(ethers.utils.formatEther(data.lockedAmount)).toFixed(2) : "0";
    const lockingTime = data ? new Date(parseInt(data.lockingTime._hex, 16) * 1000).toLocaleDateString("en-IN") : "N/A";
    const unlockingTime = data ? new Date(parseInt(data.unlockingTime._hex, 16) * 1000).toLocaleDateString("en-IN") : "N/A";
    const unlocked = data ? data.unlocked : false;

    return (
        <section className="leaderboard-area">
            <div className="container">
                <div className="col-12 col-xl-6 col-lg-6 mb-2">
                    <div className="card project-card prev-project-card">
                        <div className="project-content d-flex flex-column align-items-center justify-content-center">
                            <div className="content">
                                <h4 className="m-0">{lockedAmount} TRT</h4>
                                <h6 className="mt-3 mb-0">Locked Amount</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-xl-6 col-lg-6 mb-2">
                    <div className="card project-card prev-project-card">
                        <div className="project-content d-flex flex-column align-items-center justify-content-center">
                            <div className="content">
                                <h4 className="m-0">{lockingTime}</h4>
                                <h6 className="mt-3 mb-0">Locking Date</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-xl-6 col-lg-6 mb-2">
                    <div className="card project-card prev-project-card">
                        <div className="project-content d-flex flex-column align-items-center justify-content-center">
                            <div className="content">
                                <h4 className="m-0">{unlockingTime}</h4>
                                <h6 className="mt-3 mb-0">Unlock Date</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="project-footer d-flex justify-content-center align-items-center mt-4">
                    <Web3Button
                        className="btn btn-bordered-white btn-smaller active btn-outline-success"
                        contractAddress={CONTRACT_ADDRESS}
                        action={async (contract) => {
                            if (unlocked) {
                                await contract.call("claimTokens");
                                // Optionally, refetch user info or reward info here to update UI
                            } else {
                                toast.error("Tokens are not yet unlocked.");
                            }
                        }}
                        isDisabled={!unlocked}
                    >
                        Claim Unlocked Tokens
                    </Web3Button>
                </div>
            </div>
        </section>
    );
};

export default Locker;