import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { ethers } from "ethers";
import { Web3Button, useAddress, useContract, useContractRead } from "@thirdweb-dev/react";
import { CONTRACT_ADDRESS } from "../../const/addresses";

const Matrix = () => {
    const shortenAddress = (address) => {
        if (address && address.length > 6) {
            const start = address.slice(0, 3);
            const end = address.slice(-3);
            return `${start}..${end}`;
        }
        return address;
    };
    const navigate = useNavigate();
    const address = useAddress();
    const [myMatrix, setMyMatrix] = useState(0);
    const { contract } = useContract(CONTRACT_ADDRESS);
    const [showButtons, setShowButtons] = useState(false);
    const { data, isLoading } = useContractRead(contract, "userInfo", [address]);
    const { data: firstMatrixInfo, isLoading: isFirstMatrixInfoLoading } = useContractRead(contract, "getMatrixInfo", [address, 0]);
    const { data: rewardInfo, isLoading: isRewardInfoLoading } = useContractRead(contract, "rewardInfo", [address]);
    const { data: userInfo, isLoading: isUserInfoLoading } = useContractRead(contract, "userInfo", [address]);
    useEffect(() => {
        if (data) {
            const currentMatrix = parseInt(data.currentMatrix);
            setMyMatrix(currentMatrix);
        }
    }, [data]);

    useEffect(() => {
        // Determine if buttons should be shown
        if (!isFirstMatrixInfoLoading && !isRewardInfoLoading && !isUserInfoLoading && firstMatrixInfo && rewardInfo && userInfo) {
            const firstMatrixCompleted = firstMatrixInfo[3]; // Assuming this is the completion status
            const firstMatrixClaimed = rewardInfo.firstMatrixClaimed;
            const userBlocked = userInfo.blocked;

            setShowButtons(firstMatrixCompleted && !firstMatrixClaimed && !userBlocked);
        }
    }, [firstMatrixInfo, rewardInfo, userInfo, isFirstMatrixInfoLoading, isRewardInfoLoading, isUserInfoLoading]);

    const { data: matrixInfo, isMatrixInfoLoading } = useContractRead(contract, "getMatrixInfo", [address, myMatrix - 1]);
    if (isFirstMatrixInfoLoading || isRewardInfoLoading || isUserInfoLoading) {
        return <p>Loading...</p>;
    }
    // Function to format BigNumber to readable date
    const formatStartTime = (startTime) => {
        return new Date(ethers.BigNumber.from(startTime).toNumber() * 1000).toLocaleDateString("en-IN");
    };

    if (isMatrixInfoLoading || !matrixInfo) {
        return <p>Loading matrix info...</p>;
    }

    // Prepare matrix users for display
    const matrixUsers = matrixInfo[2].map((userAddress) => shortenAddress(userAddress));
    // Fill the remaining spots with placeholders if less than 4 users
    while (matrixUsers.length < 4) {
        matrixUsers.push('---');
    }

    return (
        <section className="leaderboard-area">
            <div className="container">
                <div className="col-12 col-xl-6 col-lg-6 item">
                    <div className="card project-card prev-project-card">
                        <div className="project-content d-flex flex-column align-items-center justify-content-center">
                            <div className="content">
                                <h4 className="m-0">Current Matrix</h4>
                                <h6 className="mt-3 mb-0">Matrix No. {myMatrix}</h6>
                            </div>
                        </div>
                    </div>
                </div>
                {isMatrixInfoLoading ? (
                    <p>Loading matrix info...</p>
                ) : (
                    <>
                    <div className="table-responsive">
                        <table className="table token-content table-borderless">
                            <thead>
                                <tr>
                                    <th scope="col">Referrer</th>
                                    <th scope="col">Start Time</th>
                                    <th scope="col">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{shortenAddress(matrixInfo[0])}</td>
                                    <td>{formatStartTime(matrixInfo[1])}</td>
                                    <td>{matrixInfo[3] ? 'Completed' : 'Running'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="table-responsive">
                        <table className="table token-content table-borderless">
                            <thead>
                                <tr>
                                <th scope="col">1</th>
                                    <th scope="col">2</th>
                                    <th scope="col">3</th>
                                    <th scope="col">4</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {matrixUsers.map((user, index) => (
                                        <td key={index}>{user}</td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    </>
                )}
                {showButtons && (
                    <div className="project-footer d-flex align-items-center mt-4 mt-md-5">
                        <Web3Button
                            className="btn btn-bordered-white btn-smaller ml-4 active btn-outline-success"
                            contractAddress={CONTRACT_ADDRESS}
                            action={async (contract) => {
                                await contract.call("claimMatrixReward");
                                // Optionally, refetch user info or reward info here to update UI
                            }}
                            onError={(error) => {
                                console.error("Could not Claim! Please try after some time", error);
                                toast.error("Could not Claim! Please try after some time");
                            }}
                            onSuccess={() => {
                                toast.success("Claimed Successfully! New Matrix is placed");
                                navigate('/matrix');
                            }}
                        >
                            Claim Matrix
                        </Web3Button>
                        <Web3Button
                            className="btn btn-bordered-white btn-smaller ml-4 active btn-outline-danger"
                            contractAddress={CONTRACT_ADDRESS}
                            action={async (contract) => {
                                await contract.call("quitMatrix");
                            }}
                            onError={(error) => {
                                console.error("Unable to quit Programme", error);
                                toast.error("Unable to quit Programme");
                            }}
                            onSuccess={() => {
                                toast.success("Matrix Programme Quited! You are blocked Now");
                                navigate('/dashboard');
                            }}
                        >
                            Quit Matrix
                        </Web3Button>
                    </div>
                )}
            </div>
        </section>
    );
};

export default Matrix;