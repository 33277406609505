import React, { useEffect, useState } from 'react';
import { useAddress, useContract, useContractRead } from "@thirdweb-dev/react";
import { CONTRACT_ADDRESS } from "../../const/addresses";

const Referral = () => {
    const address = useAddress();
    const { contract } = useContract(CONTRACT_ADDRESS);
    const { data, isLoading } = useContractRead(contract, "getReferralCounts", [address]);

    // Convert BigNumber array to readable array
    const referralCounts = data ? data.map((count) => count.toNumber()) : [];

    return (
        <section className="staking-area">
            <div className="container">
                {isLoading ? (
                    <p>Loading referral counts...</p>
                ) : (
                    <div className="d-flex flex-wrap"> {/* Enable flex container */}
                        {referralCounts.map((count, index) => (
                            // Use flex-grow for each item or set a basis
                            <div key={index} className="p-2 flex-fill"> {/* flex-fill makes each item take equal space */}
                                <div className="card project-card prev-project-card">
                                    <div className="project-content d-flex flex-column align-items-center justify-content-center">
                                        <div className="content">
                                            <h4 className="m-0">Level: {index + 1}</h4>
                                            <h6 className="mt-3 mb-0">Referrals: {count}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </section>
    );
};

export default Referral;