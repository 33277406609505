import React, { useEffect, useState } from 'react';
import { ethers } from "ethers";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { useAddress, useContract, useContractRead, useContractWrite, useBalance, Web3Button } from "@thirdweb-dev/react";
import { CONTRACT_ADDRESS, ZERO_ADDRESS, DEFAULT_REFERRER, TOKEN_ADDRESS, ORACLE_ADDRESS } from "../../const/addresses";

const Withdraw = () => {
    const [withdrawAmount, setWithdrawAmount] = useState('');
    const [isValidAmount, setIsValidAmount] = useState(true);

    const [myAmbRewards, setMyAmbRewards] = useState(0);
    const [myAvlBal, setMyAvlBal] = useState(0);
    const [myLevRewards, setMyLevRewards] = useState(0);
    const [myMasRewards, setMyMasRewards] = useState(0);
    const [myMatRewards, setMyMatRewards] = useState(0);
    const [myTopRewards, setMyTopRewards] = useState(0);
    const [myTotRewards, setMyTotRewards] = useState(0);
    const [myWithDrawnBal, setMyWithDrawnBal] = useState(0);
    const address = useAddress();

    const {contract} = useContract(CONTRACT_ADDRESS);

    const { data: rewardData,
        refetch: refetchRewardData,
        isLoading: isRewardDataLoading } = useContractRead(contract, "rewardInfo", [address]);
    console.log(rewardData);
    useEffect(() => {
        setInterval(() => {
        refetchRewardData();
        }, 10000);
      }, []);

    useEffect(() => {
        if (rewardData) {
            const myAmbRewards = parseInt(rewardData.ambassadorRewards) / 1000000;
            setMyAmbRewards(myAmbRewards);
            const myAvlBal = parseInt(rewardData.avlBal) / 1000000;
            setMyAvlBal(myAvlBal);
            const myLevRewards = parseInt(rewardData.levelRewards) / 1000000;
            setMyLevRewards(myLevRewards);
            const myMasRewards = parseInt(rewardData.masterRewards) / 1000000;
            setMyMasRewards(myMasRewards);
            const myMatRewards = parseInt(rewardData.matrixRewards) / 1000000;
            setMyMatRewards(myMatRewards);
            const myTopRewards = parseInt(rewardData.topazRewards) / 1000000;
            setMyTopRewards(myTopRewards);
            const myTotRewards = parseInt(rewardData.totalRewards) / 1000000;
            setMyTotRewards(myTotRewards);
            const myWithDrawnBal = parseInt(rewardData.withdrawnBal) / 1000000;
            setMyWithDrawnBal(myWithDrawnBal);
        }
    }, [rewardData]);

    const handleWithdrawAmountChange = (event) => {
        const inputAmount = event.target.value;
        setWithdrawAmount(inputAmount);

        // Convert input to a BigNumber for comparison
        const inputAmountInWei = ethers.utils.parseUnits(inputAmount || '0', 6); // Assuming 6 decimals like USDC
        const totalRewardsInWei = ethers.utils.parseUnits(myTotRewards.toString(), 6);

        // Validation checks
        const isValid = inputAmountInWei.lte(totalRewardsInWei) && 
                        inputAmountInWei.gte(ethers.utils.parseUnits('10', 6)) &&
                        inputAmountInWei.lte(ethers.utils.parseUnits('5000', 6));
        
        setIsValidAmount(isValid);
    };

    const performWithdraw = async (contract) => {
        if (!isValidAmount) {
            toast.error("Invalid withdraw amount.");
            return;
        }
        try {
            await contract.call("withdraw", [ethers.utils.parseUnits(withdrawAmount, 6)]);
        } catch (error) {
            console.error("Withdraw failed:", error);
            toast.error("Withdraw failed.");
        }
    };


  return (
    <section className="staking-area">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-12">
            <div className="card no-hover staking-card single-staking">
              <h4 className="m-0">Withdraw Your Rewards</h4>
              <div className="input-box my-4">
                <div className="input-area d-flex flex-column flex-md-row mb-3">
                  <div className="input-text mb-1">
                    <input type="text" placeholder={" $" + myMatRewards} disabled/>
                    <a href="#">Matrix</a>
                  </div>
                  <div className="input-text mb-1">
                  <input type="text" placeholder={" $" + myLevRewards} disabled/>
                    <a href="#">Level</a>
                  </div>
                  <div className="input-text mb-1">
                  <input type="text" placeholder={" $" + myTopRewards} disabled/>
                    <a href="#">Topaz</a>
                  </div>
                  <div className="input-text mb-1">
                  <input type="text" placeholder={" $" + myMasRewards} disabled/>
                    <a href="#">Master</a>
                  </div>
                  <div className="input-text mb-1">
                  <input type="text" placeholder={" $" + myAmbRewards} disabled/>
                    <a href="#">Ambassador</a>
                  </div>
                  <div className="input-text mb-1">
                  <input type="text" placeholder={" $" + myTotRewards} disabled/>
                    <a href="#">Total</a>
                  </div>
                  <div className="input-text mb-1">
                  <input type="text" placeholder={" $" + myWithDrawnBal} disabled/>
                    <a href="#">Withdrawn</a>
                  </div>
                  <div className="input-text mb-1">
                  <input type="text" placeholder={" $" + myAvlBal} disabled/>
                    <a href="#">Available</a>
                  </div>
                    <div className="input-text mb-1">
                        <input 
                            type="text" 
                            value={withdrawAmount} 
                            onChange={handleWithdrawAmountChange} 
                            placeholder="Input withdraw Amount"
                        />
                        <a>USDT</a>
                    </div>
                  {/* <a href="#" className="btn input-btn mt-2 mt-md-0 ml-md-3">
                    Withdraw
                  </a> */}
                  <Web3Button
                    className={`btn input-btn mt-2 mt-md-0 ml-md-3 btn-primary`}
                    contractAddress={CONTRACT_ADDRESS}
                    action={async (contract) => {
                        if (!isValidAmount) {
                          throw new Error("Invalid withdraw amount."); // This will be caught by onError
                        }
                        
                        // Assuming 'withdraw' is the correct method name and it expects the amount in a specific unit
                        await contract.call("withdraw", [ethers.utils.parseUnits(withdrawAmount, 6)]);
                    }}
                    onError={(error) => {
                    console.error("Withdraw failed:", error);
                    toast.error("Withdraw failed.");
                    }}
                    onSubmit={() => console.log("Transaction submitted")}
                    
                    onSuccess={() => {
                        toast.success("Withdraw successful!");
                        window.location.reload();
                    }}

                    isDisabled={!isValidAmount}
                    
                  >
                    Withdraw
                  </Web3Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Withdraw;
