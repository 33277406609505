import React, { Component } from 'react';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../components/Header/Header';
import Hero from '../components/Hero/Hero';
import ProjectSingle from '../components/Project/ProjectSingle';
import Content from '../components/Content/Content';
import Cta from '../components/Cta/Cta';
import Footer from '../components/Footer/Footer';
import ModalMenu from '../components/Modal/ModalMenu';
import Registration from '../components/Registration/Registration';
// import RegisterModal from '../components/RegisterModal/RegisterModal';

class ThemeOne extends Component {
  render() {
    return (
      <div className="main">
        {/* <RegisterModal /> */}
        <Header />
        <Registration />
        <ToastContainer />
        <Hero />
        <ProjectSingle />
        {/* <Content /> */}
        {/* <Cta /> */}
        
        <Footer />
        <ModalMenu />
      </div>
    );
  }
}

export default ThemeOne;