import React, { useEffect, useState } from "react";
import { ConnectWallet } from "@thirdweb-dev/react";
const Header = () => {
    const [ready, setReady] = useState(false);

    useEffect(() => {
      setReady(true);
    }, []);
    return (
        <header id="header">
            {/* Navbar */}
            <nav data-aos="zoom-out" data-aos-delay={800} className="navbar gameon-navbar navbar-expand">
                <div className="container header">
                    {/* Logo */}
                    <a className="navbar-brand" href="/">
                        <img src="/img/logo_trust.png" alt="Brand Logo" />
                    </a>
                    <div className="ml-auto" />
                    {/* Navbar Nav */}
                    <ul className="navbar-nav items mx-auto">
                        <li className="nav-item">
                            <a href="/" className="nav-link">Home</a>
                        </li>
                        <li className="nav-item">
                            <a href="/dashboard" className="nav-link">Dashboard</a>
                        </li>
                        <li className="nav-item">
                            <a href="/stake" className="nav-link">Stake</a>
                        </li>
                        <li className="nav-item">
                            <a href="/matrix" className="nav-link">Matrix</a>
                        </li>
                        <li className="nav-item">
                            <a href="/locker" className="nav-link">Locker</a>
                        </li>
                        {/* <li className="nav-item">
                            <a href="/release" className="nav-link">Release</a>
                        </li> */}
                        <li className="nav-item">
                            <a href="/withdraw" className="nav-link">Withdraw</a>
                        </li>
                        <li className="nav-item">
                            <a href="/referral" className="nav-link">Referral</a>
                        </li>
                        {/* <li className="nav-item">
                            <a href="/history" className="nav-link">History</a>
                        </li>
                        <li className="nav-item">
                            <a href="/login" className="nav-link">Login</a>
                        </li> */}
                    </ul>
                    {/* Navbar Icons */}
                    {/* Navbar Toggler */}                    
                    {/* Navbar Action Button */}
                   
                    <ul className="navbar-nav action">
                        <li className="nav-item ml-2">
                        <ConnectWallet
                                btnTitle="Connect Wallet"
                                switchToActiveChain={true}
                                modalSize={"compact"}
                                showThirdwebBranding={false}
                                
                            />
                        </li>
                    </ul>
                    <ul className="navbar-nav toggle">
                        <li className="nav-item">
                            <a href="#" className="nav-link" data-toggle="modal" data-target="#menu">
                                <i className="icon-menu m-0" />
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
    );
};

export default Header;