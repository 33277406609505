import { useAddress, useContract, useContractRead } from "@thirdweb-dev/react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CONTRACT_ADDRESS } from "../../const/addresses";
import React, { useState, useEffect } from 'react';

const initData = {
    sub_heading: "Exclusive",
    heading: "My Activites",
    btn: "View Tokenomics",
    actionBtn: "Load More"
}

const Dashboard = () => {
    const shortenAddress = (address) => {
        if (address && address.length > 8) {
            const start = address.slice(0, 4);
            const end = address.slice(-4);
            return `${start}...${end}`;
        }
        return address;
    }
    const address = useAddress();
    console.log(address);
    const { contract } = useContract(CONTRACT_ADDRESS);
    const { data, isLoading } = useContractRead(contract, "userInfo", [address]);
    console.log(data);
    

    const [myMatrix, setMyMatrix] = useState(0);
    const [myDirectTeam, setMyDirectTeam] = useState(0);
    const [myTotalTeam, setMyTotalTeam] = useState(0);
    const [myLevel, setMyLevel] = useState(0);
    const [myRef, setMyRef] = useState(null);
    const [myAmbRewards, setMyAmbRewards] = useState(0);
    const [myAvlBal, setMyAvlBal] = useState(0);
    const [myLevRewards, setMyLevRewards] = useState(0);
    const [myMasRewards, setMyMasRewards] = useState(0);
    const [myMatRewards, setMyMatRewards] = useState(0);
    const [myTopRewards, setMyTopRewards] = useState(0);
    const [myTotRewards, setMyTotRewards] = useState(0);
    const [myWithDrawnBal, setMyWithDrawnBal] = useState(0);
    const [myStake, setMyStake] = useState(0);
    const [referralLink, setReferralLink] = useState('');

    useEffect(() => {
      if (address) {
        const newReferralLink = `${window.location.origin}/?referrer=${address}`;
        setReferralLink(newReferralLink);
      }
    }, [address]);

    const copyToClipboard = async () => {
      try {
        await navigator.clipboard.writeText(referralLink);
        toast.success('Referral link copied to clipboard!');
      } catch (err) {
        console.error('Failed to copy:', err);
        toast.error('Failed to copy link.');
      }
    };

    useEffect(() => {
        if (data) {
            const currentMatrix = parseInt(data.currentMatrix);
            setMyMatrix(currentMatrix);
            const myRef = data.referrer;
            setMyRef(myRef);
            const myStake = parseInt(data.deposit) / 1000000;
            setMyStake(myStake);
            const myDirectTeam = parseInt(data.totalReferrals);
            setMyDirectTeam(myDirectTeam);
            const myTotalTeam = parseInt(data.teamCount);
            setMyTotalTeam(myTotalTeam);
        }
    }, [data]);
    
    const { data: levelData, isLoading: isLeveDataLoading } = useContractRead(contract, "getUserLevel", [address]);
    useEffect(() => {
        if (levelData) {
            const myLevel = parseInt(levelData);
            setMyLevel(myLevel);
        }
    }, [data]);
    const { data: rewardData, isLoading: isRewardDataLoading } = useContractRead(contract, "rewardInfo", [address]);
    
    useEffect(() => {
        if (rewardData) {
            const myAmbRewards = parseInt(rewardData.ambassadorRewards) / 1000000;
            setMyAmbRewards(myAmbRewards);
            const myAvlBal = parseInt(rewardData.avlBal) / 1000000;
            setMyAvlBal(myAvlBal);
            const myLevRewards = parseInt(rewardData.levelRewards) / 1000000;
            setMyLevRewards(myLevRewards);
            const myMasRewards = parseInt(rewardData.masterRewards) / 1000000;
            setMyMasRewards(myMasRewards);
            const myMatRewards = parseInt(rewardData.matrixRewards) / 1000000;
            setMyMatRewards(myMatRewards);
            const myTopRewards = parseInt(rewardData.topazRewards) / 1000000;
            setMyTopRewards(myTopRewards);
            const myTotRewards = parseInt(rewardData.totalRewards) / 1000000;
            setMyTotRewards(myTotRewards);
            const myWithDrawnBal = parseInt(rewardData.withdrawnBal) / 1000000;
            setMyWithDrawnBal(myWithDrawnBal);
        }
    }, [data]);


    return (
        <section className="explore-area prev-project-area">
          <div className="container">
            <div className="row">
              <div className="col-12">
                {/* Intro */}
                <div className="intro d-flex justify-content-between align-items-end m-0">
                  <div className="intro-content">
                    <h3 className="mt-3 mb-0">{initData.heading}</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="row items">
              <div className="col-12 col-xl-6 col-lg-6 item">
                <div className="card project-card prev-project-card">
                  {/* <div className="project-content d-md-flex flex-column flex-md-row align-items-center justify-content-md-between"> */}
                    {/* <div className="item-header d-flex align-items-center mb-md-0"> */}
                      {/* <div className="content ml-4"> */}
                        <h4 className="m-0 mb-2">My Referral Link</h4>
                        <div className="input-group">
                          <input type="text" className="form-control" value={referralLink} disabled />
                          <div className="input-group-append">
                            <button className="btn btn-outline-secondary btn-smaller rounded-right" type="button" onClick={copyToClipboard}>Copy</button>
                          </div>
                        </div>
                      {/* </div> */}
                    {/* </div> */}
                  {/* </div> */}
                </div>
              </div>
              <div className="col-12 col-xl-6 col-lg-6 item">
                <div className="card project-card prev-project-card">
                  <div className="project-content d-md-flex flex-column flex-md-row align-items-center justify-content-md-between">
                    <div className="item-header d-flex align-items-center mb-md-0">
                      <img
                        className="card-img-top avatar-max-lg"
                        src={"/img/thumb_6.png"}
                        alt=""
                      />
                      <div className="content ml-4">
                        <h4 className="m-0">{myLevel}</h4>
                        <h6 className="mt-3 mb-0">My Rank</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-xl-6 col-lg-6 item">
                <div className="card project-card prev-project-card">
                  <div className="project-content d-md-flex flex-column flex-md-row align-items-center justify-content-md-between">
                    <div className="item-header d-flex align-items-center mb-md-0">
                      <img
                        className="card-img-top avatar-max-lg"
                        src={"/img/thumb_7.png"}
                        alt=""
                      />
                      <div className="content ml-4">
                        <h4 className="m-0">{myMatrix}</h4>
                        <h6 className="mt-3 mb-0">My Current Matrix</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-xl-6 col-lg-6 item">
                <div className="card project-card prev-project-card">
                  <div className="project-content d-md-flex flex-column flex-md-row align-items-center justify-content-md-between">
                    <div className="item-header d-flex align-items-center mb-md-0">
                      <img
                        className="card-img-top avatar-max-lg"
                        src={"/img/thumb_6.png"}
                        alt=""
                      />
                      <div className="content ml-4">
                        <h4 className="m-0">{myStake}</h4>
                        <h6 className="mt-3 mb-0">My Stake</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-xl-6 col-lg-6 item">
                <div className="card project-card prev-project-card">
                  <div className="project-content d-md-flex flex-column flex-md-row align-items-center justify-content-md-between">
                    <div className="item-header d-flex align-items-center mb-md-0">
                      <img
                        className="card-img-top avatar-max-lg"
                        src={"/img/thumb_6.png"}
                        alt=""
                      />
                      <div className="content ml-4">
                        <h4 className="m-0">{shortenAddress(myRef)}</h4>
                        <h6 className="mt-3 mb-0">My Referrer</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-xl-6 col-lg-6 item">
                <div className="card project-card prev-project-card">
                  <div className="project-content d-md-flex flex-column flex-md-row align-items-center justify-content-md-between">
                    <div className="item-header d-flex align-items-center mb-md-0">
                      <img
                        className="card-img-top avatar-max-lg"
                        src={"/img/thumb_6.png"}
                        alt=""
                      />
                      <div className="content ml-4">
                        <h4 className="m-0">{myLevRewards}</h4>
                        <h6 className="mt-3 mb-0">My Level Rewards</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-xl-6 col-lg-6 item">
                <div className="card project-card prev-project-card">
                  <div className="project-content d-md-flex flex-column flex-md-row align-items-center justify-content-md-between">
                    <div className="item-header d-flex align-items-center mb-md-0">
                      <img
                        className="card-img-top avatar-max-lg"
                        src={"/img/thumb_6.png"}
                        alt=""
                      />
                      <div className="content ml-4">
                        <h4 className="m-0">{myMatRewards}</h4>
                        <h6 className="mt-3 mb-0">My Matrix Rewards</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-xl-6 col-lg-6 item">
                <div className="card project-card prev-project-card">
                  <div className="project-content d-md-flex flex-column flex-md-row align-items-center justify-content-md-between">
                    <div className="item-header d-flex align-items-center mb-md-0">
                      <img
                        className="card-img-top avatar-max-lg"
                        src={"/img/thumb_6.png"}
                        alt=""
                      />
                      <div className="content ml-4">
                        <h4 className="m-0">{myTopRewards}</h4>
                        <h6 className="mt-3 mb-0">My Topaz Rewards</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-xl-6 col-lg-6 item">
                <div className="card project-card prev-project-card">
                  <div className="project-content d-md-flex flex-column flex-md-row align-items-center justify-content-md-between">
                    <div className="item-header d-flex align-items-center mb-md-0">
                      <img
                        className="card-img-top avatar-max-lg"
                        src={"/img/thumb_6.png"}
                        alt=""
                      />
                      <div className="content ml-4">
                        <h4 className="m-0">{myMasRewards}</h4>
                        <h6 className="mt-3 mb-0">My Master Rewards</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-xl-6 col-lg-6 item">
                <div className="card project-card prev-project-card">
                  <div className="project-content d-md-flex flex-column flex-md-row align-items-center justify-content-md-between">
                    <div className="item-header d-flex align-items-center mb-md-0">
                      <img
                        className="card-img-top avatar-max-lg"
                        src={"/img/thumb_6.png"}
                        alt=""
                      />
                      <div className="content ml-4">
                        <h4 className="m-0">{myAmbRewards}</h4>
                        <h6 className="mt-3 mb-0">My Ambassador Rewards</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-xl-6 col-lg-6 item">
                <div className="card project-card prev-project-card">
                  <div className="project-content d-md-flex flex-column flex-md-row align-items-center justify-content-md-between">
                    <div className="item-header d-flex align-items-center mb-md-0">
                      <img
                        className="card-img-top avatar-max-lg"
                        src={"/img/thumb_6.png"}
                        alt=""
                      />
                      <div className="content ml-4">
                        <h4 className="m-0">{myTotRewards}</h4>
                        <h6 className="mt-3 mb-0">My Total Rewards</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-xl-6 col-lg-6 item">
                <div className="card project-card prev-project-card">
                  <div className="project-content d-md-flex flex-column flex-md-row align-items-center justify-content-md-between">
                    <div className="item-header d-flex align-items-center mb-md-0">
                      <img
                        className="card-img-top avatar-max-lg"
                        src={"/img/thumb_6.png"}
                        alt=""
                      />
                      <div className="content ml-4">
                        <h4 className="m-0">{myAvlBal}</h4>
                        <h6 className="mt-3 mb-0">My Available Balance</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-xl-6 col-lg-6 item">
                <div className="card project-card prev-project-card">
                  <div className="project-content d-md-flex flex-column flex-md-row align-items-center justify-content-md-between">
                    <div className="item-header d-flex align-items-center mb-md-0">
                      <img
                        className="card-img-top avatar-max-lg"
                        src={"/img/thumb_6.png"}
                        alt=""
                      />
                      <div className="content ml-4">
                        <h4 className="m-0">{myWithDrawnBal}</h4>
                        <h6 className="mt-3 mb-0">My Withdrawn Balance</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-xl-6 col-lg-6 item">
                <div className="card project-card prev-project-card">
                  <div className="project-content d-md-flex flex-column flex-md-row align-items-center justify-content-md-between">
                    <div className="item-header d-flex align-items-center mb-md-0">
                      <img
                        className="card-img-top avatar-max-lg"
                        src={"/img/thumb_6.png"}
                        alt=""
                      />
                      <div className="content ml-4">
                        <h4 className="m-0">{myDirectTeam}</h4>
                        <h6 className="mt-3 mb-0">My Direct Team</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-xl-6 col-lg-6 item">
                <div className="card project-card prev-project-card">
                  <div className="project-content d-md-flex flex-column flex-md-row align-items-center justify-content-md-between">
                    <div className="item-header d-flex align-items-center mb-md-0">
                      <img
                        className="card-img-top avatar-max-lg"
                        src={"/img/thumb_6.png"}
                        alt=""
                      />
                      <div className="content ml-4">
                        <h4 className="m-0">{myTotalTeam}</h4>
                        <h6 className="mt-3 mb-0">My Total Team</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    );
  }

export default Dashboard;