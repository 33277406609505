import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// importing all the themes
import ThemeOne from "../themes/theme-one";
import Dashboard from "../themes/dashboard";
import Stake from "../themes/stake";
import Release from "../themes/release";
import Withdraw from "../themes/withdraw";
import Referral from "../themes/referral";
import History from "../themes/history";
import Matrix from "../themes/matrix";
import Locker from "../themes/locker";

class MyRouts extends React.Component {
  render() {
    return (
      <div>
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={ <ThemeOne /> } />
            <Route exact path="/dashboard" element={ <Dashboard />} />
            <Route exact path="/stake" element={ <Stake />} />
            <Route exact path="/release" element={ <Release />} />
            <Route exact path="/withdraw" element={ <Withdraw />} />
            <Route exact path="/referral" element={ <Referral />} />
            <Route exact path="/history" element={ <History />} />
            <Route exact path="/matrix" element={ <Matrix />} />
            <Route exact path="/locker" element={ <Locker />} />
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}
export default MyRouts;