import React, { useEffect, useState } from 'react';
import { ethers } from "ethers";
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
// import AddTokenButton from "./AddTokenButton"

import { useAddress, useContract, useContractRead, useContractWrite, useBalance, Web3Button } from "@thirdweb-dev/react";
import { CONTRACT_ADDRESS, TOKEN_ADDRESS, ORACLE_ADDRESS } from "../../const/addresses";

const Stake = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const address = useAddress();
    const { contract: matrixContract } = useContract(CONTRACT_ADDRESS);
    
    const { data: userInfo, isLoading: isLoadingUserInfo } = useContractRead(matrixContract, "userInfo", [address]);
    const { contract: tokenContract } = useContract(TOKEN_ADDRESS);
    
    console.log(userInfo);
    const fixedUsdcAmount = "40"; // Fixed staking amount in USDC (human-readable format)
    const [isStaked, setIsStaked] = useState(false); // State to track if the user has already staked

    const { data: nativeData, isLoading: isLoadingNativeData } = useBalance();
    const { data: tokenData, isLoading: isLoadingTokenData } = useBalance(TOKEN_ADDRESS);
    

    const { contract: oracleContract } = useContract(ORACLE_ADDRESS);
    const { mutateAsync: estimateAmountOut } = useContractWrite(oracleContract, "estimateAmountOut");
    const [usdcValue, setUsdcValue] = useState('');
    const [isEstimating, setIsEstimating] = useState(false);

    const attemptEstimateAmountOut = async (retries = 3, delay = 1000) => {
        setIsEstimating(true); // Indicate loading
        let lastError = null;

        for (let i = 0; i < retries; i++) {
            try {
                const tokenAmount = tokenData.value;
                const amountString = tokenAmount.toString();
                const weiValue = ethers.utils.parseUnits(amountString, 'wei');
                const data = await estimateAmountOut({ args: [TOKEN_ADDRESS, weiValue, 5] }); // Example args
                const stringValue = ethers.BigNumber.from(data);
                const numberValue = ethers.utils.formatUnits(stringValue, 6);
                const amountUsdc = parseFloat(numberValue).toFixed(1);
                setUsdcValue(amountUsdc); // Update state with successful result
                setIsEstimating(false); // Loading complete
                return; // Exit after successful call
            } catch (error) {
                lastError = error;
                await new Promise(resolve => setTimeout(resolve, delay)); // Wait before retrying
            }
        }

        setIsEstimating(false); // Loading complete, call ultimately failed
        console.error("Final attempt failed with error:", lastError);
        toast.error("Failed to estimate amount out. Please try again later.");
    };

    useEffect(() => {
        if (!isLoadingTokenData && tokenData) {
            attemptEstimateAmountOut();
        }
    }, [isLoadingTokenData, tokenData]);

    const [myMatic, setMyMatic] = useState(null);
    const [myTrtBal, setMyTrtBal] = useState(null);
    useEffect(() => {
        if (tokenData) {
            const mytrtBalance = tokenData.displayValue;
            const myTrtBal = parseFloat(mytrtBalance).toFixed(1);
            setMyTrtBal(myTrtBal);
        }
    }, [tokenData]);

    useEffect(() => {
        if (nativeData) {
            const myMaticBalance = nativeData.displayValue;
            const myMatic = parseFloat(myMaticBalance).toFixed(1);
            setMyMatic(myMatic);
        }
    }, [isLoadingNativeData, nativeData]);

    useEffect(() => {
        if (userInfo && userInfo.currentMatrix != 0) {
            setIsStaked(true);
        } else {
            setIsStaked(false);
        }
    }, [isLoadingUserInfo, userInfo]);


    return (
        <section className="staking-area">
            <div className="container">
                <div className="col-12 col-md-12">
                    <div className="card no-hover staking-card single-staking">
                    <div className="tab-container">
                        <ul className="nav nav-tabs staking-tabs border-0 my-3 my-md-4">
                            <li className="nav-item" role="presentation">
                                <a className="tab-link active" id="tab-one-tab" data-toggle="tab" href="#tab-one" role="tab" aria-selected="true">
                                    TRT
                                </a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className="tab-link active" id="tab-one-tab" data-toggle="tab" href="#tab-one" role="tab" aria-selected="true">
                                    ≈ USDT
                                </a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className="tab-link active" id="tab-one-tab" data-toggle="tab" href="#tab-one" role="tab" aria-selected="true">
                                    Matic
                                </a>
                            </li>
                        </ul>
                        
                        <ul className="nav nav-tabs staking-tabs border-0 my-3 my-md-4">
                            <li className="nav-item" role="presentation">
                                <a className="tab-link active" id="tab-one-tab" data-toggle="tab" href="#tab-one" role="tab" aria-selected="true">
                                    {myTrtBal}
                                </a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className="tab-link active" id="tab-one-tab" data-toggle="tab" href="#tab-one" role="tab" aria-selected="true">
                                    ≈{usdcValue}
                                </a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className="tab-link active" id="tab-one-tab" data-toggle="tab" href="#tab-one" role="tab" aria-selected="true">
                                    {myMatic}
                                </a>
                            </li>
                        </ul>
                    </div>
                        <h4 className="m-0">Stake TRT of $40 Value</h4>
                        <div className="input-box my-4">
                            <div className="input-area d-flex flex-column flex-md-row">
                                <div className="input-text">
                                    <input type="text" placeholder={40} disabled/>
                                    <a>USDT</a>
                                </div>
                                <Web3Button
                                    className="btn input-btn mt-2 mt-md-0 ml-md-3 active btn-primary"
                                    contractAddress={CONTRACT_ADDRESS}
                                    action={async (contract) => {
                                        await tokenContract?.erc20.setAllowance(
                                            CONTRACT_ADDRESS, 100
                                        );
                                        await contract.call("buyMatrix", [
                                            ethers.utils.parseUnits(fixedUsdcAmount, 6)
                                        ])
                                    }}
                                    onError={(error) => {
                                        console.error("Staking failed:", error);
                                        toast.error("Staking failed:", error);
                                    }}
                                    onSuccess={() => {
                                        toast.success("Staking successful");
                                        navigate('/dashboard');
                                    }}
                                    isDisabled={isStaked} // This will disable the button if isStaked is true
                                >
                                    {isStaked ? "Already Staked" : "Stake"}
                                </Web3Button>
                            </div>
                            {/* <AddTokenButton /> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Stake;